import React from 'react'
import { Card } from 'react-bootstrap'
import Rating from './Rating'
import { Link } from 'react-router-dom'
import { USANA_PRODUCT_URL } from '../constants/productConstants'



function Product({ product }) {

    // console.log(product.code)

    return (
        <Card className='my-3 p-3 rounded'>
            {product.brand !== 'Usana' ? 
                <Link to={`/product/${product._id}`}>
                    <Card.Img src={product.image} />
                </Link> 
                :
                <a href={USANA_PRODUCT_URL + product.code} target="_blank" rel="noreferrer">
                     <Card.Img src={product.image} />
                </a>
            }

            <Card.Body>
                {product.brand !== 'Usana' ? 
                    <Link to={`/product/${product._id}`}>
                        <Card.Title as="div">
                            <strong>{product.name}</strong>
                        </Card.Title>
                    </Link> 
                    :
                    <a href={USANA_PRODUCT_URL + product.code} target="_blank" rel="noreferrer">
                        <Card.Title as="div">
                            <strong>{product.name}</strong>
                        </Card.Title>
                    </a>
                }
                {/* <Link to={`/product/${product._id}`}>
                    <Card.Title as="div">
                        <strong>{product.name}</strong>
                    </Card.Title>
                </Link> */}

                <Card.Text as="div">
                    <div className='my-3'>
                        <Rating value={product.rating} text={`${product.numReviews} reviews`} color={'#f8e825'} />
                    </div>
                </Card.Text>

                <Card.Text as="h3">
                    ${product.price}
                </Card.Text>
            </Card.Body>

        </Card>
    )
}

export default Product