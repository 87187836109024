export const PRODUCT_DELIVER_INFO_REQUEST = 'PRODUCT_DELIVER_INFO_REQUEST'
export const PRODUCT_DELIVER_INFO_SUCCESS = 'PRODUCT_DELIVER_INFO_SUCCESS'
export const PRODUCT_DELIVER_INFO_FAIL = 'PRODUCT_DELIVER_INFO_FAIL'
// export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const PRODUCT_SELECTOR_LIST_REQUEST = 'PRODUCT_SELECTOR_LIST_REQUEST'
export const PRODUCT_SELECTOR_LIST_SUCCESS = 'PRODUCT_SELECTOR_LIST_SUCCESS'
export const PRODUCT_SELECTOR_LIST_FAIL = 'PRODUCT_SELECTOR_LIST_FAIL'

export const PRODUCT_REGISTER_REQUEST = 'PRODUCT_REGISTER_REQUEST'
export const PRODUCT_REGISTER_SUCCESS = 'PRODUCT_REGISTER_SUCCESS'
export const PRODUCT_REGISTER_FAIL = 'PRODUCT_REGISTER_FAIL'