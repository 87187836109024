import axios from 'axios'
import {
    PRODUCT_DELIVER_INFO_REQUEST,
    PRODUCT_DELIVER_INFO_SUCCESS,
    PRODUCT_DELIVER_INFO_FAIL,

    PRODUCT_SELECTOR_LIST_REQUEST,
    PRODUCT_SELECTOR_LIST_SUCCESS,
    PRODUCT_SELECTOR_LIST_FAIL,

    PRODUCT_REGISTER_REQUEST,
    PRODUCT_REGISTER_SUCCESS,
    PRODUCT_REGISTER_FAIL,
} from '../constants/productDeliverConstants'



export const getCommonInfo = () => async ( dispatch ) => {
    try {
        dispatch({
            type: PRODUCT_DELIVER_INFO_REQUEST
        })

        const config = {
            headers:{
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            'api/admin/dashboard',
            config
        )

        dispatch({
            type: PRODUCT_DELIVER_INFO_SUCCESS,
            payload: data
        })

        localStorage.setItem('commonInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: PRODUCT_DELIVER_INFO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const getProductSelectorList = () => async ( dispatch ) => {
    try {
        dispatch({
            type: PRODUCT_SELECTOR_LIST_REQUEST
        })

        const config = {
            headers:{
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            'api/admin/dashboard',
            config
        )

        dispatch({
            type: PRODUCT_SELECTOR_LIST_SUCCESS,
            payload: data
        })

        localStorage.setItem('product_selector_list', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: PRODUCT_SELECTOR_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const registerProduct = ( name, link, extra_info, notes, price ) => async ( dispatch ) => {
    try {
        dispatch({
            type: PRODUCT_REGISTER_REQUEST
        })

        const config = {
            headers:{
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            'api/admin/register_ml_product',
            { 'name': name, 'link': link, 'extra_info': extra_info, 'notes': notes, 'price': price },
            config
        )

        dispatch({
            type: PRODUCT_REGISTER_SUCCESS,
            payload: data
        })

        // localStorage.setItem('product_selector_list', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: PRODUCT_REGISTER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
