import FormContainer from '../../components/FormContainer'
// import Loader from '../components/Loader'
// import Message from '../components/Message'
import Modal from 'react-modal';
import React, { useState, useEffect, useRef, TextBox } from 'react'
import Select from 'react-select'
import { Form, Button, Row, Col, } from 'react-bootstrap'
import { qrCreate } from '../../actions/it_tools/itToolsActions'
import { useDispatch, useSelector } from 'react-redux'



function ItToolsScreen({ location, history }) {
    
    const dispatch = useDispatch()
    const productDeliverInfo = useSelector(state => state.productDeliverInfo)
    const redirect = location.search ? location.search.split('=')[1] : '/'
    
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    
    const [extra_info, setExtraInfo] = useState('')
    const [link, setLink] = useState('')
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [notes, setNotes] = useState('')
    const [price, setPrice] = useState('226')
    const [selectedOption, setSelectedOption] = useState(null);
    
    let [modal_message, setModalMessage] = useState('Modal Message');
    let [phone_number, setPhoneNumber] = useState('52');
    let [qr_code_image_base64_string, setQrCodeImageBase64String] = useState(null);
    let [qr_domain, setQrDomain] = useState('www.some.com')

    let subtitle;
    let to_get_products_info = []
    
    Modal.setAppElement('#root');

    useEffect(() => {

        dispatch(qrCreate(qr_domain));

        // if not qr_code_image_base64_string in the dirst render, create a default one
        if(!qr_code_image_base64_string){
        // create qr image
            dispatch(qrCreate(qr_domain));
            // timeout to can get the qrCreate(qr_domain) response from localstorage
            setTimeout(() => {
                setQrCodeImageBase64String(localStorage.getItem('qr_code_image_base64_string'));
            }, '100');
        }

    }, [ history, userInfo, redirect, qr_code_image_base64_string])
    
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = 'white';
    }

    const page_styles = {
        backgroundColor: "rgb(28, 28, 30)",
        container: {
            backgroundColor: "red",
            width: "100%",
        },
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: 'blue',
            backgroundColor: 'rgb(39, 122, 210)'
        }
    };

    function submitHandler() {

    }

    const selectorStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            height: '50px',
            '&:hover': {
                borderWidth: '5px',
                borderColor: state.isFocused ? 'rgb(104, 196, 129)' : 'rgb(104, 196, 129)'
            },
            
        }),
    };


    function updateQrCode( qr_domain ) {
        // create a new qr image from the user input
        dispatch(qrCreate( qr_domain ));
        // timeout to can get the qrCreate(qr_domain) response from localstorage
        setTimeout(() => {
            setQrCodeImageBase64String(localStorage.getItem('qr_code_image_base64_string'));
        }, '100');
    }
    

    return (
        <>
            <style>
                {
                    `main {
                        background-color: rgb(63, 62, 62);
                    }`
                }
            </style>
            <div>

                {/* <button onClick={ openModal }>Open Modal</button> */}
                <Modal
                    isOpen = {modalIsOpen}
                    onAfterOpen = {afterOpenModal}
                    contentLabel = "Example Modal"
                    style = {customStyles}
                    
                >
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{ modal_message }</h2>
                </Modal>
                
                <div style = { page_styles }>

                    <p>IT TOOLS</p>

                    {/* {to_get_products_info?.map(product => (
                        <div key={product.id}>
                            <p>This is the name: {product.name}</p>
                        </div>
                    ))} */}

                    {/* {to_get_comon_info?.map(order => (
                        <div key={product.id}>
                            <p>{order.header}</p>
                            <p>{order.footer}</p>
                        </div>
                    ))} */}

                    <div style={{ display: 'flex', flexWrap: 'wrap', height: '70px' }}>
                        <div style={{ width: '50%', height: '50px', padding: '10px' }} >
                        {/* margin: '20px 0px 0px 25px',  */}
                            
                            <Select
                                styles = {selectorStyles}
                            
                                defaultValue={ selectedOption }
                                
                                // options={options}
                                options = {to_get_products_info?.map(( product, i ) => ({
                                    key: product.id,
                                    label: product.name,
                                    value: product.label,
                                    product_index: i
                                }))}
                                onChange = {(choice) => setSelectedOption(choice) }
                            />
                        </div>
                        <div style = {{ width: '50%', padding: '10px' }}>
                            <input
                                style = {{ width: '100%', height: '100%',}}
                                onChange = {(e) => setPhoneNumber(e.target.value)}
                                placeholder = 'Phone'
                                required = {true}
                                type = 'text'
                                value = { phone_number }
                            />
                        </div>
                    </div>
                    


                    <FormContainer>

                        {/* {error && <Message variant='danger'>{error}</Message>}
                        {loading && <Loader />} */}

                        <Form onSubmit={ submitHandler } style={{ marginBottom: '20px' }}>

                            <Form.Group controlId='text'>
                                <Form.Label className='register_ml_product_label'>Domain</Form.Label>
                                <Form.Control
                                    as="textarea" 
                                    rows={1}
                                    required
                                    type='textarea'
                                    placeholder='Enter text'
                                    value = { qr_domain }
                                    onChange={(e) => {
                                        setQrDomain(e.target.value);
                                        updateQrCode(e.target.value);
                                    }}

                                    
                                >
                                </Form.Control>
                                {/* <Form.Control as="textarea" rows={3} /> */}
                            </Form.Group>

                            <Form.Group controlId='link'>
                                <Form.Label className='register_ml_product_label'>Link</Form.Label>
                                <Form.Control
                                    required
                                    type='link'
                                    placeholder='Enter link'
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='extra_info'>
                                <Form.Label className='register_ml_product_label'>Extra info</Form.Label>
                                <Form.Control
                                    // required
                                    type='extra_info'
                                    placeholder='Enter extra info'
                                    value={extra_info}
                                    onChange={(e) => setExtraInfo(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='notes'>
                                <Form.Label className='register_ml_product_label'>Notes</Form.Label>
                                <Form.Control
                                    // required
                                    type='notes'
                                    placeholder='Enter notes'
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='price'>
                                <Form.Label className='register_ml_product_label'>Price</Form.Label>
                                <Form.Control
                                    required
                                    type='number'
                                    placeholder='Enter price'
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            <img src={`data:image/png;base64,${qr_code_image_base64_string}`}></img>

                        </Form>

                    </FormContainer>
                </div>
            </div>
        </>
    )
}

export default ItToolsScreen

