import axios from 'axios'
import {
    QR_CREATE_REQUEST,
    QR_CREATE_SUCCESS,
    QR_CREATE_FAIL,
} from '../../constants/it_tools/itToolsConstants'



export const qrCreate = ( qr_domain ) => async ( dispatch ) => {
    try {
        dispatch({
            type: QR_CREATE_REQUEST
        })

        const config = {
            headers:{
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            'api/it-tools/qr-code-generator/',
            { qr_domain: qr_domain },
            config
        )

        dispatch({
            type: QR_CREATE_SUCCESS,
            payload: data
        })

        localStorage.setItem('qr_code_image_base64_string', data.qr_code_image_base64_string)


    } catch (error) {
        dispatch({
            type: QR_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
