import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Image, Form, Button, Card } from 'react-bootstrap'
import Message from '../components/Message'
import { addToCart, removeFromCart } from '../actions/cartActions'
import { createGuestOrderAction } from '../actions/orderActions'



function CartScreen({ match, location, history }) {

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const productId = match.params.id
    const qty = location.search ? Number(location.search.split('=')[1]) : 1
    
    const dispatch = useDispatch()

    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

    // const orderGuestCreate = useSelector(state => state.orderGuestCreate)
    // const { order } = orderGuestCreate

    

    useEffect(() => {

        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
          console.log("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
          console.log(
            "Order canceled -- continue to shop around and checkout when you're ready."
          );
          console.log('DEBUG', )
        }

        if(productId){
            dispatch(addToCart(productId, qty))
        }

        
    }, [dispatch, productId, qty])

    const removeFromCartHandler = (id) => {
        dispatch(removeFromCart(id))
    }

    const checkoutHandler = () => {
        history.push('/login?redirect=shipping')
    }

    const guestCheckoutHandler = () => {
        if ( userInfo ) {
            var email = userInfo.email
            var product_id = cartItems[0].product

            localStorage.setItem('email', email);
            
            dispatch(createGuestOrderAction(email, product_id))

            // localStorage.setItem('order_id', order._id);
            // localStorage.setItem('order_total_price', order.totalPrice);
            
            history.push('/stripe-redirect-screen')
        } else {
            history.push('/stripe-payment-screen')
        }
        
    }

    return (
        <Row>
            <Col md={8}>
                <h1>Shopping cart</h1>
                {cartItems.length === 0 ? (
                    <Message variant='info'>
                        Your cart is empty <Link to='/'>Go back</Link>
                    </Message>
                ) : (
                    <ListGroup variant='flush'>
                        {cartItems.map(item => (
                            <ListGroup.Item key={item.product}>
                                <Row>
                                    <Col md={2}>
                                        <Image src={item.image} alt={item.name} fluid rounded />
                                    </Col>

                                    <Col md={3}>
                                        <Link to={`/product/${item.product}`}>{item.name}</Link>
                                    </Col>

                                    <Col md={2}>
                                        ${item.price}
                                    </Col>

                                    <Col md={3}>
                                        <Form.Control
                                            as="select"
                                            value={item.qty}
                                            onChange={(e) => dispatch(addToCart(item.product, Number(e.target.value)))}
                                        >
                                            {
                                                [...Array(item.countInStock).keys()].map((x) => (
                                                    <option key={x + 1} value={x + 1}>
                                                        {x + 1}
                                                    </option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Col>

                                    <Col md={1}>
                                        <Button
                                            type='button'
                                            variant='light'
                                            onClick={() => removeFromCartHandler(item.product)}
                                        >
                                            <i className='fas fa-trash'></i>
                                        </Button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                        
                    </ListGroup>
                )}
            </Col>

            <Col md={4}>
                <Card>
                    <ListGroup variant='flush'>
                        <ListGroup.Item>
                            <h2>Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)}) items</h2>
                            ${cartItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2)}
                        </ListGroup.Item>
                    </ListGroup>

                    <ListGroup.Item>
                        {/* <Button
                            type='button'
                            className='btn-block'
                            disabled={cartItems.length === 0}
                            onClick={checkoutHandler}
                        >
                            Proceed To Checkout
                        </Button> */}

                        {/* <form action="/api/orders/create-checkout-session" method="POST">
                            <Button
                                className='btn-block'
                                disabled={cartItems.length === 0}
                                type='submit'
                            >
                                Proceed To Checkout
                            </Button>
                        </form> */}

                        <Button
                            type='button'
                            className='btn-block'
                            disabled={ cartItems.length === 0 }
                            onClick={ guestCheckoutHandler }
                        >
                            Proceed To Checkout
                        </Button>
                    </ListGroup.Item>
                </Card>
            </Col>
        </Row>
    )
}

export default CartScreen