import { Container } from 'react-bootstrap'
import { HashRouter as Router, Route } from 'react-router-dom'

import Header from "./components/Header"
import Footer from "./components/Footer"

import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import OrderListScreen from './screens/OrderListScreen'

import DashboardScreen from './screens/DashboardScreen'

import UsanaCodesEditScreen from './screens/UsanaCodesEditScreen'
import UsanaCodesListScreen from './screens/UsanaCodesListScreen'
import DeliverOrderScreen from './screens/DeliverOrderScreen'

import StripePaymentScreen from './screens/StripePaymentScreen'
import StripeRedirectScreen from './screens/StripeRedirectScreen'

import ItToolsScreen from './screens/it_tools/ItToolsScreen'



function App() {
  return (
    <Router>
      <Header />
        <main className='py-3'>
          <Container>
            <Route path='/' component={HomeScreen} exact />
            <Route path='/register' component={RegisterScreen} />
            <Route path='/profile' component={ProfileScreen} />
            <Route path='/login' component={LoginScreen} />
            <Route path='/product/:id' component={ProductScreen} />
            <Route path='/shipping' component={ShippingScreen} />
            <Route path='/placeorder' component={PlaceOrderScreen} />
            <Route path='/order/:id' component={OrderScreen} />
            <Route path='/payment' component={PaymentScreen} />
            <Route path='/cart/:id?' component={CartScreen} />

            <Route path='/admin/dashboard' component={DashboardScreen} />

            <Route path='/admin/userlist' component={UserListScreen} />
            <Route path='/admin/user/:id/edit' component={UserEditScreen} />

            <Route path='/admin/productlist' component={ProductListScreen} />
            <Route path='/admin/product/:id/edit' component={ProductEditScreen} />

            <Route path='/admin/orderlist' component={OrderListScreen} />

            <Route path='/admin/usana-codes-edit-screen' component={UsanaCodesEditScreen} />
            <Route path='/admin/usana-codes-list-screen' component={UsanaCodesListScreen} />

            <Route path='/orders/deliver' component={DeliverOrderScreen} />

            <Route path='/stripe-payment-screen' component={StripePaymentScreen} />
            <Route path='/stripe-redirect-screen' component={StripeRedirectScreen} />

            <Route path='/it-tools' component={ItToolsScreen} />
          </Container>
        </main>
        <a href="https://wa.me/524521808018?text=Hello%20from%20Pixiomarket:%20" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
          <i className="fa fa-whatsapp whatsapp-icon"></i>
        </a>
      <Footer />
    </Router>
  );
}

export default App;






// import { Container } from 'react-bootstrap'
// import { HashRouter as Router, Route } from 'react-router-dom'
// import Header from './components/Header'
// import Footer from './components/Footer'
// import HomeScreen from './screens/HomeScreen'
// import ProductScreen from './screens/ProductScreen'
// import CartScreen from './screens/CartScreen'
// import LoginScreen from './screens/LoginScreen'
// import RegisterScreen from './screens/RegisterScreen'
// import ProfileScreen from './screens/ProfileScreen'
// import ShippingScreen from './screens/ShippingScreen'
// import PaymentScreen from './screens/PaymentScreen'
// import PlaceOrderScreen from './screens/PlaceOrderScreen'
// import OrderScreen from './screens/OrderScreen'
// import UserListScreen from './screens/UserListScreen'
// import UserEditScreen from './screens/UserEditScreen'
// import ProductListScreen from './screens/ProductListScreen'
// import ProductEditScreen from './screens/ProductEditScreen'
// import OrderListScreen from './screens/OrderListScreen'


// function App() {
//   return (
//     <Router>
//       <Header />
//       <main className="py-3">
//         <Container>
//           <Route path='/' component={HomeScreen} exact />
//           <Route path='/login' component={LoginScreen} />
//           <Route path='/register' component={RegisterScreen} />
//           <Route path='/profile' component={ProfileScreen} />
//           <Route path='/shipping' component={ShippingScreen} />
//           <Route path='/placeorder' component={PlaceOrderScreen} />
//           <Route path='/order/:id' component={OrderScreen} />
//           <Route path='/payment' component={PaymentScreen} />
//           <Route path='/product/:id' component={ProductScreen} />
//           <Route path='/cart/:id?' component={CartScreen} />

//           <Route path='/admin/userlist' component={UserListScreen} />
//           <Route path='/admin/user/:id/edit' component={UserEditScreen} />

//           <Route path='/admin/productlist' component={ProductListScreen} />
//           <Route path='/admin/product/:id/edit' component={ProductEditScreen} />

//           <Route path='/admin/orderlist' component={OrderListScreen} />
//         </Container>
//       </main>
//       <Footer />
//     </Router>
//   );
// }

// export default App;
