import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'


function SearchBox() {
    const [keyword, setKeyword] = useState('')

    let history = useHistory()

    const submitHandler = (e) => {
        e.preventDefault()
        if (keyword) {
            history.push(`/?keyword=${keyword}&page=1`)
        } else {
            history.push(history.push(history.location.pathname))
        }
    }
    return (
        <Form onSubmit={submitHandler} className='inline_row'>
            <Form.Control
                type='text'
                placeholder='Search on Pixiomarket'
                name='q'
                onChange={(e) => setKeyword(e.target.value)}
                className='mr-sm-2 ml-sm-5'
            ></Form.Control>

            <div className='search_button'>
                <Button
                    type='submit'
                    variant='outline-success'
                    className='inline_row'
                    // className='pl-9'
                >
                    Search
                </Button>
            </div>
        </Form>
    )
}

export default SearchBox