import QueryString from 'query-string';
import React, { useEffect, useState } from 'react'
import { confirmIfOrderIsPaid } from '../actions/orderActions'
import { removeFromCart } from '../actions/cartActions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';



function DeliverOrderScreen(  ) {

    const dispatch = useDispatch()

    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

    const [message, setMessage] = useState('');

    const location = useLocation();

    var email = localStorage.getItem('email');
    var order_id = localStorage.getItem('order_id');
    var order_total_price = localStorage.getItem('order_total_price');

    if ( cartItems[0] ) {
        var product_id = cartItems[0].product
    }
    

// values from stripe checkout for success or canceled
    const values = QueryString.parse(location.search);
    // const values = 'true';

    useEffect(() => {
        
        if (values.success) {
            setMessage('Order placed! You will receive an email confirmation (and link if your purchase is for a digital product) and you can login to your account to view your order history when you need it.');
        }

        if (values.canceled) {
            setMessage("We didn't receive your payment --- Continue to checkout when you're ready.");
        }
    }, [location.search, values.canceled, values.success]);



    // if (typeof orderStripePay.order === 'undefined' || orderStripePay.order === null) {
    if (values.canceled) {
        return (
            <div>
                {/* <h1>LOADING</h1> */}
                {message}
                <p>If you want to download a previous order, you have to login</p>
                <p>You can contact us for help: softin4325@outlook.com</p>

            </div>
        )
    } 
    
    if (values.success) {
    // if (values === 'true') {

        if ( order_id ){
            dispatch(confirmIfOrderIsPaid(email, order_id, order_total_price))
            localStorage.setItem('cartItems', '');
            localStorage.setItem('email', '');
            localStorage.setItem('order_id', '');
            localStorage.setItem('order_total_price', '');
        }

        if ( cartItems[0] ) {
            dispatch(removeFromCart(product_id))
            var product_name = cartItems[0].name
        }



        return (
            <div>
                <div>
                    <h1>Order placed!</h1>
                    <p>{product_name}</p>
                    <br/>
                    <h5>You will receive an email confirmation and link if your purchase is for a digital product.</h5>
                    <p>You can login to your account to view your order history when you need it. !!!</p>
                    
                    {/* <p>Order status: {orderStripePay.order.payment_status}</p> */}
                    
                    {/* <p>Direct download link: <a href={orderStripePay.order.product_link}>{cartItems[0].name}</a></p> */}
                    <br/>
                    {/* <p>You can watch your purchase directly here but we recommend you to download it:</p> */}
                    {/* <iframe src={orderStripePay.order.product_link} width="940" height="900" allow="autoplay"/> */}

                    {/* <div dangerouslySetInnerHTML={link} /> */}
                    {/* <iframe src="https://drive.google.com/file/d/1DvaIvVKu19rplnsOeQZ-WZUsTS-o80KB/preview" width="640" height="480" allow="autoplay"></iframe> */}
                </div>
            </div>
        )
    }

    if (!values.canceled) {
        return (
            <div>
                <p>Not data received</p>

            </div>
        )
    } 
}
// http://localhost:3000/#/orders/deliver?success=true&session_id=cs_test_a11ozVFbGHsvOjYUv0HuD1jBbUzSGCppWcAg2D8IjXBsVJewUd9NIGeVnA


export default DeliverOrderScreen
