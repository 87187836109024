import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'
import ReCAPTCHA from "react-google-recaptcha";



function RegisterScreen({ location, history }) {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/'

    const userRegister = useSelector(state => state.userRegister)
    const { error, loading, userInfo } = userRegister

    const [captchaValido, cambiarCaptchaValido] = useState(null)
    const [usuarioValido, cambiarUsuarioValido] = useState(null)


    useEffect(() => {
        if(userInfo) {
            history.push(redirect)
        }
    }, [ history, userInfo, redirect ])

    const submitHandler = (e) => {
        e.preventDefault()

        if (captcha.current.getValue()) {
            cambiarCaptchaValido(true)
        } else {
            cambiarCaptchaValido(false)
        }

        if(password != confirmPassword){
            setMessage('Passwords do not match!')
        } else {
            dispatch(register(name, email, password))
        }
    }

    const captcha = useRef(null);

    function onChange(value) {
        if (captcha.current.getValue()) {
            cambiarCaptchaValido(true)
        }
    }

    return (
        <FormContainer>
            <h1>Sign In</h1>

            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}

            <Form onSubmit={ submitHandler }>

                <Form.Group controlId='name'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        required
                        type='name'
                        placeholder='Enter Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='email'>
                    <Form.Label>Email Adress</Form.Label>
                    <Form.Control
                        required
                        type='email'
                        placeholder='Enter Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        required
                        type='password'
                        placeholder='Enter Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='passwordConfirm'>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        required
                        type='password'
                        placeholder='Confirm Password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                {message && <Message variant='danger'>{message}</Message>}

                <div className='recaptcha'>
                    <ReCAPTCHA
                        ref={captcha}
                        sitekey="6LdhDDEhAAAAAGKzroG3-6LAl00qBjKXpXgS3TmQ"
                        onChange={onChange}
                    />
                </div>
                {captchaValido === false && <Message variant='danger'>Please solve the robot captcha!</Message>}

                <Button type='submit' variant='primary'>
                    Register
                </Button>

            </Form>

            <Row className='py-3'>
                <Col>
                    Have an account? <Link
                        to={redirect ? `/login?redirect=${redirect}` : '/login'}
                    >
                        Sign in
                    </Link>
                </Col>
            </Row>

        </FormContainer>
    )
}

export default RegisterScreen