import { useSelector } from 'react-redux'
import React, { useEffect } from 'react'



function StripeRedirectScreen({ history }) {
   
    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

    var email = localStorage.getItem('email');
    var product_id = cartItems[0].product

    const orderGuestCreate = useSelector(state => state.orderGuestCreate)
    const { order } = orderGuestCreate

    if ( order ){
        localStorage.setItem('order_id', order._id);
        localStorage.setItem('order_total_price', order.totalPrice);
    } else {
        window.location.reload(true)
    }

    
    
    useEffect(() => {

        document.getElementById('myform').submit();
    }, [ email, product_id, history ])



    return (
// CHECK THIS to avoid error on stripe payment
        <div>
            <h1>REDIRECTING TO PAYMENT</h1>
            {/* <form id="myform" action='http://localhost:8000/api/orders/stripe-checkout' method="POST"> */}
            <form id="myform" action='https://www.pixiomarket.com/api/orders/stripe-checkout' method="POST">
            

                <input type="email" hidden className="form-control" name="email" defaultValue={email}></input>

                <input type="text" hidden className="form-control" name="product_id" defaultValue={cartItems[0].product}></input>
                
                {/* <button type="submit" className='btn btn-primary'>
                    Buy as visitor
                </button> */}
            </form>
        </div>
    )
}



export default StripeRedirectScreen