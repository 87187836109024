import { Form, Button, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { login } from '../actions/userActions'
// import { searchUser } from '../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import Loader from '../components/Loader'
import Message from '../components/Message'
import React, { useState, useEffect } from 'react'



function LoginScreen({ location, history }) {

    const dispatch = useDispatch()
    const redirect = location.search ? location.search.split('=')[1] : '/'

    const userLogin = useSelector(state => state.userLogin)
    const { error, loading, userInfo } = userLogin
    const [email, setEmail] = useState('')

    const [password, setPassword] = useState('')

    

    useEffect(() => {
        if(userInfo) {
            history.push(redirect)
            // history.push('/')
        }
    }, [ history, userInfo, redirect ])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(email, password))
        // history.push('/')
    }
    


    return (
        <FormContainer>

{/* this is to add a component here on the html page */}
            {/* <Router>
                <Route path='/' component={Stripe}></Route>
            </Router> */}

            <br></br>
            <h1>Sign In</h1>

            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}

            <Form onSubmit={ submitHandler }>
                <Form.Group controlId='email'>
                    <Form.Label>Email Adress</Form.Label>
                    <Form.Control
                        type='email'
                        placeholder='Enter Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder='Enter Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary'>
                    Sign In
                </Button>
            </Form>
            <br></br>

            <Row className='py-3'>
                <Col>
                    New customer? <Link
                        to={redirect ? `/register?redirect=${redirect}` : '/register'}
                    >
                        Register
                    </Link>
                </Col>
            </Row>

            <Row>
                <Link to={`/orders/deliver`} >
                    <Button variant='primary' >
                        Link
                    </Button>
                </Link>
            </Row>
        </FormContainer>
    )
}



export default LoginScreen