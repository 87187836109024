import FormContainer from '../components/FormContainer'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Modal from 'react-modal';
import React, { useState, useEffect, useRef, TextBox } from 'react'
import Select from 'react-select'
import { Form, Button, Row, Col, } from 'react-bootstrap'
import { getCommonInfo, registerProduct } from '../actions/productDeliverActions'
import { useDispatch, useSelector } from 'react-redux'

import Iframe from 'react-iframe'



function DashboardScreen({ location, history }) {
    
    const dispatch = useDispatch()
    const productDeliverInfo = useSelector(state => state.productDeliverInfo)
    const redirect = location.search ? location.search.split('=')[1] : '/'
    
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const { commonInfo } = productDeliverInfo
    
    const [extra_info, setExtraInfo] = useState('')
    const [link, setLink] = useState('')
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [name, setName] = useState('')
    const [notes, setNotes] = useState('')
    const [price, setPrice] = useState('226')
    const [selectedOption, setSelectedOption] = useState(null);
    
    let [modal_message, setModalMessage] = useState('Modal Message');
    let subtitle;
    let to_get_comon_info = []
    let to_get_products_info = []
    let [phone_number, setPhoneNumber] = useState('52');

    Modal.setAppElement('#root');

    useEffect(() => {

        if (!userInfo || !userInfo.isAdmin) {
            history.push('/login')
        } 

        dispatch(getCommonInfo()); // put common info on local storage

    }, [ history, userInfo, redirect, ])
    
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = 'white';
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(registerProduct(name, link, extra_info, notes, price))

// modal for registered ML product
        setModalMessage('Done')
        setIsOpen(true);
        setTimeout(() => {
            setIsOpen(false);
        }, "2000");

        setName('')
        setLink('')
    }

    const page_styles = {
        backgroundColor: "rgb(28, 28, 30)",
        container: {
            backgroundColor: "red",
            width: "100%",
        },
    };

// function copy to clipboard
    function copy_to_clipboard(text){
        navigator.clipboard.writeText(text)
// modal for copied text
        setModalMessage('Copied')
        setIsOpen(true);
        setTimeout(() => {
            setIsOpen(false);
        }, "1500");
    }

    if ( commonInfo ) {
        to_get_comon_info = commonInfo.common_info
        to_get_products_info = commonInfo.products_info
    }

// function send whatsapp
    function send_whatsapp( chat ){

        console.log(chat)

        phone_number = phone_number.replace(/-|\s/g, '');

        if ( (chat === 'chat') && (phone_number.length !== 12) ) {
            setModalMessage('Missing or wrong information')
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
            }, "2000");
            return;
        } else if ( (chat !== 'chat') && (!selectedOption || phone_number.length !== 12) ) {
            setModalMessage('Missing or wrong information')
            setIsOpen(true);
            setTimeout(() => {
                setIsOpen(false);
            }, "2000");
            return;
        }

        if ( chat === 'chat' ) {
            window.open('https://api.whatsapp.com/send?phone=' + phone_number + '&text=Hola. ');
            setPhoneNumber('52')
            return;
        }

        let whatsapp_text = window.encodeURIComponent(commonInfo.products_info[selectedOption.product_index].name + '\n\n' + commonInfo.common_info[0].header + '\n' + commonInfo.products_info[selectedOption.product_index].link + '\n\n' + commonInfo.common_info[0].footer )

        window.open('https://api.whatsapp.com/send?phone=' + phone_number + '&text=' + whatsapp_text);

        setPhoneNumber('52')

        // console.log( commonInfo.products_info[selectedOption.product_index].name + '\n\n' + commonInfo.common_info[0].header + '\n\n' + commonInfo.products_info[selectedOption.product_index].link + '\n\n' + commonInfo.common_info[0].footer )
        
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: 'blue',
            backgroundColor: 'rgb(39, 122, 210)'
        }
    };

    const selectorStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            height: '50px',
            '&:hover': {
                borderWidth: '5px',
                borderColor: state.isFocused ? 'rgb(104, 196, 129)' : 'rgb(104, 196, 129)'
            },
            
        }),
    };

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('image', file)
        // formData.append('product_id', productId)

        // setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            // const { data } = await axios.post('/api/products/upload/', formData, config)
            
            // setImage(data)
            // setUploading(false)

        } catch (error) {
            // setUploading(false)
        }
    }

    let chat = 'chat'

    let country = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (country === 'America/Mexico_City') {
        console.log('Pesos')
    } else {
        console.log('Dollars')
    }
    


    return (
        <>
            <style>
                {
                    `main {
                        background-color: rgb(63, 62, 62);
                    }`
                }
            </style>
            
            <div>

                {/* <button onClick={ openModal }>Open Modal</button> */}
                <Modal
                    isOpen = {modalIsOpen}
                    onAfterOpen = {afterOpenModal}
                    contentLabel = "Example Modal"
                    style = {customStyles}
                    
                >
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{ modal_message }</h2>
                </Modal>
                
                <div style = { page_styles }>

                    {/* {to_get_products_info?.map(product => (
                        <div key={product.id}>
                            <p>This is the name: {product.name}</p>
                        </div>
                    ))} */}

                    {/* {to_get_comon_info?.map(order => (
                        <div key={product.id}>
                            <p>{order.header}</p>
                            <p>{order.footer}</p>
                        </div>
                    ))} */}

                    <div>
                        <div>
                            <button className="copy_button" onClick={() => copy_to_clipboard('www.pixiomarket.com')}>www.pixiomarket.com</button>
                        </div>

                        <div>
                            <button className="copy_button" onClick={() => copy_to_clipboard('Sí está disponible en versión digital en idioma inglés, se envía gratis la información al número de wsp que nos proporciones por mensaje privado en cuanto se acredite tu pago. Listo para entrega inmediata. ADELANTE CON TU COMPRA!!!')}>Disponible y se envía...</button>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexWrap: 'wrap', height: '70px' }}>
                        <div style={{ width: '50%', height: '50px', padding: '10px' }} >
                        {/* margin: '20px 0px 0px 25px',  */}
                            
                            <Select
                                styles = {selectorStyles}
                            
                                defaultValue={ selectedOption }
                                
                                // options={options}
                                options = {to_get_products_info?.map(( product, i ) => ({
                                    key: product.id,
                                    label: product.name,
                                    value: product.label,
                                    product_index: i
                                }))}
                                onChange = {(choice) => setSelectedOption(choice) }
                            />
                        </div>
                        <div style = {{ width: '50%', padding: '10px' }}>
                            <input
                                style = {{ width: '100%', height: '100%',}}
                                onChange = {(e) => setPhoneNumber(e.target.value)}
                                placeholder = 'Phone'
                                required = {true}
                                type = 'text'
                                value = { phone_number }
                            />
                        </div>
                    </div>

                    

                    <div>
                        <button className="copy_button" onClick={() => send_whatsapp()}>WhatsApp</button>
                    </div>

                    <div>
                        <button className="copy_button" onClick={() => send_whatsapp(chat)}>Chat</button>
                    </div>

                    <div style={{ margin: '20px' }}>
                        {/* { commonInfo } */}
                        {/* { footer } */}
                    </div>
                    


                    <FormContainer>

                        {/* {error && <Message variant='danger'>{error}</Message>}
                        {loading && <Loader />} */}

                        <Form onSubmit={ submitHandler } style={{ marginBottom: '20px' }}>

                            <Form.Group controlId='name'>
                                <Form.Label className='register_ml_product_label'>Name</Form.Label>
                                <Form.Control
                                    required
                                    type='name'
                                    placeholder='Enter Name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='link'>
                                <Form.Label className='register_ml_product_label'>Link</Form.Label>
                                <Form.Control
                                    required
                                    type='link'
                                    placeholder='Enter link'
                                    value={link}
                                    onChange={(e) => setLink(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='extra_info'>
                                <Form.Label className='register_ml_product_label'>Extra info</Form.Label>
                                <Form.Control
                                    // required
                                    type='extra_info'
                                    placeholder='Enter extra info'
                                    value={extra_info}
                                    onChange={(e) => setExtraInfo(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='notes'>
                                <Form.Label className='register_ml_product_label'>Notes</Form.Label>
                                <Form.Control
                                    // required
                                    type='notes'
                                    placeholder='Enter notes'
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId='price'>
                                <Form.Label className='register_ml_product_label'>Price</Form.Label>
                                <Form.Control
                                    required
                                    type='number'
                                    placeholder='Enter price'
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>

                            {/* {message && <Message variant='danger'>{message}</Message>} */}

                            <Button type='submit' variant='primary' className='copy_button'>
                                Register
                            </Button>
                            <Form.Group>
                                <Form.File
                                    id='image-file'
                                    label='Choose excel file'
                                    custom
                                    onChange={uploadFileHandler}
                                >
                                </Form.File>

                                {/* {uploading && <Loader />} */}
                            </Form.Group>

                        </Form>

                        

                    </FormContainer>
                </div>
            </div>
            <Iframe 
                url='https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=America%2FMexico_City&src=aXNyYWVsbWVkZWxiQGdtYWlsLmNvbQ&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=ZmFtaWx5MTgwMDE3NzI3NjU1MzY3OTAzNjRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23E67C73&color=%2333B679&color=%23E4C441" style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"'
                width="1000px"
                height="400px"
                id=""
                className=""
                display="block"
                position="relative"/>
            </>
    )
}

export default DashboardScreen