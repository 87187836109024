import { useDispatch, useSelector } from 'react-redux'
import { listProductDetails, updateProduct } from '../actions/productActions'
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants'
import axios from 'axios'
import { Form, Button } from 'react-bootstrap'
import FormContainer from '../components/FormContainer'
import React, { useState, useEffect } from 'react'




function UsanaCodesListScreen({ match, history }) {

    const [name, setName] = useState('')
    const [product_code, setProductCode] = useState('')

    const dispatch = useDispatch()

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateProduct({
            name,
            product_code
        }))
    }

    return (
        <div>
            <FormContainer>
                <h1>Usana codes list</h1>
                <Form onSubmit={ submitHandler }>

                        <Form.Group controlId='name'>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type='name'
                                placeholder='Enter Name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId='product_code'>
                            <Form.Label>Product code</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter product code'
                                value={product_code}
                                onChange={(e) => setProductCode(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                </Form>
            </FormContainer>
        </div>
    )
}

export default UsanaCodesListScreen