import { Form, Button, Row, Col } from 'react-bootstrap'
// import { Link } from 'react-router-dom'
// import { login } from '../actions/userActions'
// import { searchUser } from '../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
// import Loader from '../components/Loader'
// import Message from '../components/Message'
import React, { useState} from 'react'

import { createGuestOrderAction } from '../actions/orderActions'



function StripePaymentScreen({ location, history }) {

    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/'
    const [guestEmail, setGuestEmail] = useState('')
    const [password, setPassword] = useState('')
    
    const userLogin = useSelector(state => state.userLogin)
    const { error, loading } = userLogin

    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

    var product_id = cartItems[0].product
    var [email, setEmail] = useState('')

    // const submitHandler = (e) => {
    //     e.preventDefault()
    //     dispatch(login(email, password))
    //     history.push('/cart')
    // }

    const orderGuestCreate = useSelector(state => state.orderGuestCreate)
    const { order } = orderGuestCreate

    if ( order ){
        localStorage.setItem('email', guestEmail);
        localStorage.setItem('order_id', order._id);
        localStorage.setItem('order_total_price', order.totalPrice);
    }
    
    const deliverHandler = (e) => {
        e.preventDefault()

        var email = guestEmail
        
        dispatch(createGuestOrderAction(email, product_id))
        // history.push('/orders/deliver')
    }

    if ( order ) {
        history.push('/stripe-redirect-screen')
    }


    return (
        

        <FormContainer>
            <Form onSubmit={ deliverHandler }>
                <Form.Group controlId='guestEmail'>
                    <Form.Label>Email Adress</Form.Label>
                    <Form.Control
                        type='email'
                        required
                        placeholder='Enter Email'
                        value={guestEmail}
                        onChange={(e) => setGuestEmail(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary'>
                    CHECKOUT
                </Button>
            </Form>

            {/* <br></br>
            <h6>Optionally you can sign in:</h6>
            <h1>Sign In</h1>
            

            {error && <Message variant='danger'>{error}</Message>}
            {loading && <Loader />}

            <Form onSubmit={ submitHandler }>
                <Form.Group controlId='email'>
                    <Form.Label>Email Adress</Form.Label>
                    <Form.Control
                        type='email'
                        placeholder='Enter Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder='Enter Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary'>
                    Sign In
                </Button>
            </Form>
            <br></br>

            <br></br>
            <h6>Optionally you can create an account:</h6>

            <Row className='py-3'>
                <Col>
                    New customer? <Link
                        to={redirect ? `/register?redirect=${redirect}` : '/register'}
                    >
                        Register
                    </Link>
                </Col>
            </Row> */}
        </FormContainer>
    )
}



export default StripePaymentScreen