import {
    PRODUCT_DELIVER_INFO_REQUEST,
    PRODUCT_DELIVER_INFO_SUCCESS,
    PRODUCT_DELIVER_INFO_FAIL,

    PRODUCT_SELECTOR_LIST_REQUEST,
    PRODUCT_SELECTOR_LIST_SUCCESS,
    PRODUCT_SELECTOR_LIST_FAIL,

    PRODUCT_REGISTER_REQUEST,
    PRODUCT_REGISTER_SUCCESS,
    PRODUCT_REGISTER_FAIL,
} from '../constants/productDeliverConstants'



export const productDeliverInfoReducer = (state = { }, action) => {
    switch (action.type) {
        case PRODUCT_DELIVER_INFO_REQUEST:
            return { loading: true }
        
        case PRODUCT_DELIVER_INFO_SUCCESS:
            return { loading: false, commonInfo: action.payload }
        
        case PRODUCT_DELIVER_INFO_FAIL:
            return { loading: false, error: action.payload }
        
        // case USER_LOGOUT:
        //     return {  }
        
        default:
            return state
    }
}



export const productRegisterReducer = (state = { }, action) => {
    switch (action.type) {
        case PRODUCT_REGISTER_REQUEST:
            return { loading: true }
        
        case PRODUCT_REGISTER_SUCCESS:
            return { loading: false, commonInfo: action.payload }
        
        case PRODUCT_REGISTER_FAIL:
            return { loading: false, error: action.payload }
        
        default:
            return state
    }
}



export const productSelectorListReducer = (state = { }, action) => {
    switch (action.type) {
        case PRODUCT_SELECTOR_LIST_REQUEST:
            return { loading: true }
        
        case PRODUCT_SELECTOR_LIST_SUCCESS:
            return { loading: false, selectorList: action.payload }
        
        case PRODUCT_SELECTOR_LIST_FAIL:
            return { loading: false, error: action.payload }
        
        // case USER_LOGOUT:
        //     return {  }
        
        default:
            return state
    }
}
